@import "variables";

.ant-checkbox-inner {
  border-radius: 2px !important;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  &.guttered {
    margin-top: $general-gutter;
    margin-bottom: $general-gutter;
    @media (max-width: $xl) {
      padding-left: $general-gutter;
      padding-right: $general-gutter;
    }
  }
}

.image {
  &,
  img {
    max-width: 100%;
    height: auto;
  }
}

.phone-input-component {
  .ant-form-item-control-input-content {
    border-radius: 8px !important;
  }

  label {
    padding-left: 40px !important;

    &.label-float-custom {
      padding-left: 5px !important;
    }
  }
}