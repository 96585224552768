.container {
    height: 70px;
    max-width: 1440px;
    padding: 0 15px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.infoline {
    padding-right: 20px;
}