@import "variables";

body {
  padding-top: 55px + 41px;
  @media (min-width: $sm) {
    padding-top: 74px + 41px;
  }
  @media (min-width: 900px) {
    padding-top: 124px + 41px;
  }
}

.headerSection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 16;
}

#header {
  background-color: $primary-color;
  -webkit-border-bottom-right-radius: calc(2 * #{$general-radius});
  -webkit-border-bottom-left-radius: calc(2 * #{$general-radius});
  -moz-border-radius-bottomright: calc(2 * #{$general-radius});
  -moz-border-radius-bottomleft: calc(2 * #{$general-radius});
  border-bottom-right-radius: calc(2 * #{$general-radius});
  border-bottom-left-radius: calc(2 * #{$general-radius});
  color: white;
  z-index: 2;
  * {
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
  }
  a {
    text-decoration: none;
  }
  .container {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    display: flex;
    width: 100%;
    align-items: center;
    padding: $general-gutter 0 $general-gutter $general-gutter;
    z-index: 2;
    .logo-anchor {
      display: block;
      font-size: 0;
      picture,
      img {
        height: 25px;
        max-width: 180px;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }
    .message {
      display: none;
    }
  }
  .search {
    display: none;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    &.open {
      display: flex;
    }
    .search-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
      input,
      button {
        color: black;
        height: 35px;
        padding: 0;
        margin: 0;
        display: inline-block;
        border: none;
        box-shadow: none;
        background-color: rgb(242, 242, 242);
      }
      input {
        width: 100%;
        padding: 0 80px 0 20px;
        font-size: 1em;
        -webkit-border-radius: 17px;
        -moz-border-radius: 17px;
        border-radius: 17px;
        -webkit-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        -moz-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        outline: none;
        &:hover,
        &:focus {
          outline: none;
        }
      }
      button {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 60px;
        padding: 5px 15px 0;
        text-align: center;
        -webkit-border-top-right-radius: 17px;
        -webkit-border-bottom-right-radius: 17px;
        -moz-border-radius-topright: 17px;
        -moz-border-radius-bottomright: 17px;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
          background-color: #e6e6e6;
        }
        svg {
          fill: $primary-color;
        }
      }
    }
    .info {
      display: none;
    }
  }
  .menu {
    z-index: 2;
    position: relative;
    display: flex;

    .icon-menu-wrapper {
      position: relative;
      display: flex;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          color: white;
          &.show-search {
            &.homepage {
              display: none;
            }
          }
          &.show-infoline,
          &.show-stores {
            display: none;
          }
          &.hide-mobile {
            display: none;
          }
          a {
            display: flex;
            color: white;
            padding: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 1.1em;
            svg {
              fill: white;
            }
            &:hover {
              color: $secondary-color;
              svg {
                fill: $secondary-color;
              }
            }
            span.text {
              display: none;
            }
            .icon {
              transition: all 0.3s ease;
              position: relative;
              display: inline-block;

              transform: scale(0.75);
              // font-size:4em;
              .badge {
                position: absolute;
                bottom: 5px;
                left: 3px;
                margin-bottom: -11px;
                margin-left: -11px;
                font-size: 0.7em;
                font-weight: bold;
                background-color: rgb(175, 202, 5);
                color: rgb(34, 34, 34);
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-border-radius: 17px;
                -moz-border-radius: 17px;
                border-radius: 17px;
                svg {
                  fill: $primary-color;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 350px) {
    .logo {
      padding: $general-gutter 0;
    }
  }

  @media (min-width: $xs) {
    .menu {
      .icon-menu-wrapper {
        ul {
          li {
            &.show-search {
              &.homepage {
                display: block;
              }
            }
            &.show-stores,
            &.show-infoline {
              display: block;
            }
            a {
              padding: 7px;
            }
          }
        }
      }
    }
  }

  @media (min-width: $sm) {
    .logo {
      .message {
        display: block;
        height: 44px;
        border-left: 1px solid white;
        padding-left: 15px;
        font-size: 0.8em;
        display: flex;
        align-items: center;
        p,
        div {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  @media (min-width: 900px) {
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .menu {
      .icon-menu-wrapper {
        ul {
          li {
            &.show-menu {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: $md) {
    .container {
      flex-direction: row;
    }
    .logo {
      padding: 0;
      padding-left: 15px;
      order: 1;
      width: auto;
      .logo-anchor {
        padding-right: 15px;
        picture,
        img {
          height: 35px;
          max-width: 180px;
        }
      }
    }
    .search {
      display: flex;
      order: 2;
      background-color: transparent;
      width: auto;
      padding: 0;
      margin: 0 10px;
      position: relative;
      top: auto;
      left: auto;
      .search-wrapper {
        width: 400px;
        input,
        button {
          background-color: rgb(242, 242, 242);
        }
        input {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
    .menu {
      order: 3;
      background-color: transparent;
      box-shadow: none;
      width: auto;
      .icon-menu-wrapper {
        position: relative;
        display: flex;
        width: auto;
        ul {
          width: auto;

          li {
            &.show-search,
            &.show-search.homepage,
            &.show-menu,
            &.show-infoline {
              display: none;
            }
            &:last-child {
              padding-right: 20px;
            }

            a {
              padding: 20px 15px;
              color: white;
              svg {
                fill: white;
              }
              .icon {
                transform: scale(1);
              }
              &:hover {
                color: $primary-color;
                background-color: white;
                svg {
                  fill: $primary-color;
                }
              }

              span.text {
                display: block;
                margin-right: 10px;

                &--mobile {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $lg) {
    .container {
      padding: 0 50px;
    }

    .search {
      .info {
        display: block;
        margin-left: 15px;
        font-size: 0.9em;
        line-height: 1.5em;
        a {
          color: white;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
