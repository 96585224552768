$color_1: rgb(80, 35, 122);
$color_2: rgb(175, 202, 5);
$color_3: rgb(102, 102, 102);
$color_4: rgb(57, 12, 99);
$font_family_1: Quicksand, sans-serif;
$background_color_1: rgba(192, 138, 235, 0.1);

// * {
// 	-webkit-box-sizing: border-box;
// 	box-sizing: border-box;
// }
.ff-quicksand {
	body {
		font-family: $font_family_1;
	}
}
.has-submenu {
	&:after {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		// background: url(caret.svg) center no-repeat;
		background-size: 10px;
		margin-left: 4px;
	}
	&:hover {
		&:after {
			// background: url(caret-hover.svg) center no-repeat;
			background-size: 10px;
			transform: rotate(90deg);
		}
	}
}
.mo-megamenu {
	background: white;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
	position: relative;
	nav {
        z-index: 999;
		position: relative;
		max-width: 1400px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 10px;
		padding-left: 10px;
		ul {
			li.separator{
				width: 100%;
				height: 1px;
				border-bottom: 1px solid #eee;
				display:none;
			}
			a {
				text-decoration: none;
				font-size: 14px;
				&.mobile{
					display: none;
				}
			}
			margin: 0;
			padding: 0;
			list-style: none;
		}
		ul.main {
			display: flex;
			justify-content: space-around;
			position: relative;
			>li {
				>a {
					color: $color_1;
					font-weight: 700;
					display: flex;
					padding: 0 10px;
					height: 50px;
					align-items: center;
					&:hover {
						color: $color_2;
						background: rgb(250, 250, 250);
					}
					&.mobile{
						display: none;
					}
				}
			}
		}
	}
}
.mo-megamenu-item {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background: white;
	display: none;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
	flex-wrap: wrap;
	.show-more {
		display: block;
		margin-top: 15px;
		color: $color_3;
		text-decoration: underline;
	}
	a {
		display: block;
		color: $color_3;
		margin-bottom: 10px;
		&:hover {
			color: $color_4 !important;
		}
	}
	a.mo-megamenu-title {
		display: flex;
		color: $color_2;
		font-size: 14px;
		padding-bottom: 5px;
		margin-bottom: 15px;
		font-weight: 600;
		align-items: center;
		position: relative;
	}
	ul {
		width: 100%;
	}
}
.mo-column {
	width: 100%;
	position: relative;
	padding-right: 30px;
	&:not(:last-child) {
		&:after {
			content: "";
			position: absolute;
			display: block;
			right: 30px;
			top: 0px;
			height: 100%;
			width: 1px;
			opacity: 0.15;
			background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%);
		}
	}
}
.mo-column.mo-col-2 {
	width: 50%;
}
.mo-column.mo-col-3 {
	width: 33%;
}
.mo-column.mo-col-4 {
	width: 25%;
}
.mo-column.mo-col-5 {
	width: 20%;
}
.mo-column.mo-col-6 {
	width: 16%;
}
.mo-megamenu-wrap {
	display: flex;
}
.mo-megamenu-title {
	&::before {
		content: "";
		display: block;
		height: 2px;
		width: 20px;
		position: absolute;
		bottom: 0px;
		left: 0px;
		background: rgb(238, 238, 238);
	}
	img {
		margin-right: 10px;
	}
}
.mo-all {
	display: none !important;
}
@media (min-width: 900px) {
	.mo-megamenu-title.has-submenu {
		&:after {
			display: none;
		}
	}
	.mo-megamenu {
		ul.main {
			>li {
				&:hover {
					.mo-megamenu-item {
						display: flex;
					}
				}
			}
		}
	}
}
@media (max-width: 899px) {
	.mo-column.mo-col-1 {
		width: 100%;
	}
	.mo-column.mo-col-2 {
		width: 100%;
	}
	.mo-column.mo-col-3 {
		width: 100%;
	}
	.mo-column.mo-col-4 {
		width: 100%;
	}
	.mo-column.mo-col-5 {
		width: 100%;
	}
	.mo-column.mo-col-6 {
		width: 100%;
	}
	.mo-all {
		display: flex !important;
	}
	.mo-megamenu {
		nav {
			ul.main {
				box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
				position: fixed;
				left: 0;
				right: 0;
				max-height: calc(100% - 157px);
				background: white;
				flex-direction: column;
				display: none;
				overflow: auto;
				li {
					&.separator{
						display: block;
					}
					a {
						color: $color_3;
						font-weight: 700;
						display: none;
						padding: 10px;
						align-items: center;
						font-size: 17px;
						&:focus {
							background-color: $background_color_1;
						}
						&.mobile, &.always{
							display: flex;
						}
					}
				}
				>li {	
					>a {
						color: $color_2;
						background: $color_4;
						height: auto;
						padding: 10px 15px;
						border-bottom: 1px solid rgba(255,255,255,0.1);

						&:hover {
							background: $color_4;
						}

						svg {
							fill: #ffffff;
						}

						&.open {
							svg {
								transform: translateY(-50%) rotate(-180deg);
							}
						}
					}
				}
			}
			ul.main.open {
				display: block;
			}
		}
		a.active {
			color: $color_2 !important;
		}
		.mo-megamenu-item {
			a {
				padding-left: 30px !important;
				margin-bottom: 0;
				font-size: 16px !important;
			}

			ul {
				padding-left: 40px;
				a {
					font-weight: normal !important;
					font-size: 15px !important;
					padding: 5px 0 !important;
				}
			}
		}
		a.open.has-submenu {
			color: $color_1;
			svg {
				width: 14px;
				display: block;
				height: 14px;
				transform: translateY(-50%) rotate(-180deg);
			}
		}
	}
	.mo-column {
		padding-right: 0;
		&:not(:last-child) {
			&:after {
				display: none;
			}
		}
	}
	.mo-megamenu-item.open {
		display: block;
	}
	.mo-megamenu-wrap.open {
		display: block;
	}
	.mo-megamenu-wrap {
		flex-wrap: wrap;
		display: none;
		padding-left: 10px;
		a {
			padding-left: 45px !important;
		}
	}
	.mo-megamenu-item {
		position: static;
		box-shadow: none;
		padding: 0;
		margin-bottom: 0;
		height: auto;
		font-size: 14px;
	}
	.mo-megamenu-title {
		img {
			display: none;
		}
		&::before {
			display: none;
		}
	}
	.has-submenu {
		position: relative;
		margin-bottom: 0;
		svg {
			width: 14px;
			height: 14px;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%) rotate(0deg);
			fill: $color_3;
		}
	}
}
