$footer-color: rgb(57, 12, 99);

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-shadow: 0px 25px 60px -12px rgba(0,0,0,0.05);
$general-shadow-hover: 0px 10px 20px 0 rgba(0,0,0,0.1);
$general-radius: 12px;
$general-border: 1px solid #eee;

$general-transition: all 0.3s;

$discount-color: rgb(230, 59, 69);
$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);
$arriving-color: rgb(236, 62, 172);

$primary-color: rgb(80, 35, 122);
$primary-color-hover: darken($primary-color, 20%);

$secondary-color: rgb(175, 202, 5);
$secondary-background: #f7f7f7;

$text-color: rgb(17, 17, 17);
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);
$dark-purple-color: rgb(58, 25, 88);

$shadow-default: $general-shadow;
$main-bg-color: rgb(255,255,255);

$color-primary: $primary-color;