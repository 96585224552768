@import "styles/variables";

.infolineStatus {
  display: flex;
  align-items: center;

  a {
    color: white;
  }

  :global(.ant-badge) {
    color: white;
    &.online {
      sup {
        box-shadow: 0 0 0 1px $secondary-color;
        background-color: $secondary-color;
        color: $primary-color;
        font-weight: bolder;
      }
    }
    &.offline {
      sup {
        box-shadow: 0 0 0 1px $unavailable-color;
        background-color: $unavailable-color;
        color: white;
      }
    }
  }

  svg {
    margin-right: 10px;
    display: none;
  }

  span {
    line-height: 1.3em;
    a {
      font-size: 0.95em;
    }
  }
}

.infolineStatusSmall {
  svg {
    color: $primary-color;
  }
  span {
    display: block;
    font-size: 0.8em;
  }

  .fakeLink {
    color: white;
    padding: 7px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1em;
  }

  &.online {
    svg {
      color: $secondary-color;
    }
  }
  &.offline {
    svg {
      color: $unavailable-color;
    }
  }
}
