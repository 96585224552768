// PACKETERY
.packetery {
  &__custom {
	font-size: 13px;
	border: 0 !important;
	background: none !important;
	padding-left: 23px !important;
  }
}

.phone-input-component {
	.ant-form-item-control-input-content {
	  border-radius: 4px !important;
	}
	.PhoneInput {
	  padding: 4px 11px !important;
	}
  
	label {
	  padding-left: 45px !important;
  
	  &.label-float-custom {
		padding-left: 5px !important;
	  }
	}
  }